import React, { useCallback } from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { GlobalStyles } from '../../pages/questionnaire/Questionnaire';
import { hospital } from '../../graphql/schema/familyMember';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button, Container, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QuestionnaireHeader from './Header';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  lead: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(3, -1),

    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
}));

export const H1 = styled.h1`
  font-size: 1.7rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.5em;
`;

export const H3 = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.5em;
`;

export const H2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
`;

export const Paragraph = styled.p`
  font-size: ${props => (props.lead ? '1.4em' : '1.15em')};
  line-height: 1.5;
  opacity: 0.65;
`;

export const Disclaimer = styled.div`
  text-align: left;
  flex: auto;
  min-height: 0;

  overflow-y: auto;
  // border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  // box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-right: 1em;

  @media (min-width: 768px) {
    border: none;
    box-shadow: none;
    padding: 0;
    flex: 0 0 auto;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3em;

  > * + * {
    margin-left: 0.75em;
  }
`;

export const Action = styled.a`
  display: flex;
  border: 1px solid #00a7f7;
  border-radius: 0.25em;
  padding: 0.5em 0.75em;
  align-items: center;
  text-decoration: none;
  color: #00a7f7;

  > * + * {
    margin-left: 0.5em;
  }
`;

export const PrimaryAction = styled.div`
  margin-top: auto;
  margin-bottom: 1em;

  @media (min-width: 768px) {
    margin-top: 1em;
  }
`;

export const ParagraphSection = styled.div`
  font-size: ${props => (props.lead ? '1.4em' : '1.15em')};
  line-height: 1.5;
  opacity: 0.65;
`;

export const Header = styled.div``;

const QuestionnaireStartPage = ({ intake, onAccept, procedureId }) => {
  const classes = useStyles();
  return (
    <Query query={hospital}>
      {useCallback(
        ({ data: { patientHospital: hospital } = {} }) => {
          const hospitalName = get(hospital, 'name');
          const contact = get(hospital, 'contact');
          const address = get(hospital, 'address');
          return (
            <Container maxWidth="sm" className={classes.container}>
              <GlobalStyles />

              <QuestionnaireHeader
                procedureId={procedureId}
                hospitalName={hospitalName}
                contact={contact}
                address={address}
              />

              <Typography variant="h2" component="h1" align="center" gutterBottom>
                <FormattedMessage
                  id="questionnaire.welcome.title"
                  defaultMessage={intake ? 'Welcome To InTake Form Entry' : 'Welcome To Patient Online Registration'}
                />
              </Typography>

              <Typography variant="h6" component="p" className={classes.lead}>
                {hospitalName ? (
                  <FormattedMessage
                    id="questionnaire.welcome.lead.hospital"
                    defaultMessage={
                      intake
                        ? 'Please fill out this InTake Form. It is simple, secure, and confidential.'
                        : hospitalName +
                          ' needs your health information and medical history in order to provide you the best care possible. Please fill out this online questionnaire. It is simple, secure, and confidential.'
                    }
                    values={{ hospitalName }}
                  />
                ) : (
                  <FormattedMessage
                    id="questionnaire.welcome.lead.generic"
                    defaultMessage={
                      intake
                        ? 'Please fill out this InTake Form. It is simple, secure, and confidential.'
                        : 'We need your health information and medical history in order to provide you the best care possible. Please fill out this online questionnaire. It is simple, secure, and confidential.'
                    }
                  />
                )}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography>
                <FormattedMessage
                  id="questionnaire.welcome.instructions.request"
                  defaultMessage="Please make sure you have the following information available:"
                />
              </Typography>

              <ul>
                <li>
                  <Typography gutterBottom>
                    <FormattedMessage
                      id="questionnaire.welcome.instructions.prescription"
                      defaultMessage={
                        intake
                          ? 'Personal Identification'
                          : 'Names and dosage of each prescription medication you take.'
                      }
                    />
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom>
                    <FormattedMessage
                      id="questionnaire.welcome.instructions.over-the-counter"
                      defaultMessage={
                        intake
                          ? 'Insurance Information'
                          : 'Names and dosage of each over-the-counter medication you take regularly.'
                      }
                    />
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom>
                    <FormattedMessage
                      id="questionnaire.welcome.instructions.allergies"
                      defaultMessage={
                        intake
                          ? 'Advance Directive or Living Will if applicable'
                          : 'Allergies you may have and the allergic reaction(s) they cause.'
                      }
                    />
                  </Typography>
                </li>
                {!intake && (
                  <li>
                    <Typography gutterBottom>
                      <FormattedMessage
                        id="questionnaire.welcome.instructions.surgeries"
                        defaultMessage="Previous surgeries, and the year they were performed."
                      />
                    </Typography>
                  </li>
                )}
              </ul>

              <Typography gutterBottom>
                <FormattedMessage
                  id="questionnaire.welcome.call"
                  defaultMessage="Upon receiving this information, a staff member or nurse may call you for further clarification."
                />
              </Typography>
              <Typography gutterBottom>
                <FormattedMessage
                  id="questionnaire.welcome.end"
                  defaultMessage="We look forward to providing you with the highest quality medical care and service."
                />
              </Typography>

              <Box mt="auto" my={2}>
                <Button size="large" color="primary" onClick={onAccept} fullWidth>
                  {<FormattedMessage id="questionnaire.welcome.continue" defaultMessage="Continue" />}
                </Button>
              </Box>
            </Container>
          );
        },
        [classes.container, classes.lead, onAccept, procedureId]
      )}
    </Query>
  );
};

export default QuestionnaireStartPage;
