import React, { useRef } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import InputWrapper from '../InputWrapper';
import InputLabel from '../InputLabel';
import { FormattedMessage } from 'react-intl';
import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(0, -1),

    '& .MuiTextField-root': {
      margin: theme.spacing(0, 1),
    },
  },
}));

const DateField = ({ label, value, onChange }) => {
  const classes = useStyles();

  const dayRef = useRef();
  const yearRef = useRef();

  const monthName = 'month';
  const dayName = 'day';
  const yearName = 'year';

  const month = value?.date?.month ?? '';
  const day = value?.date?.day ?? '';
  const year = value?.date?.year ?? '';

  const handleChange = (name, min, max) => e => {
    const nextInputvalue = e.target.value.replace(/\D/g, '');
    const numericalNextInputValue = parseInt(nextInputvalue, '10');
    if (numericalNextInputValue < min || numericalNextInputValue > max) {
      return;
    }
    if (name === 'month') {
      if (nextInputvalue.length >= e.target.maxLength && dayRef.current) {
        dayRef.current.focus();
        dayRef.current.setSelectionRange(0, dayRef.current.value.length);
      }
    } else if (name === 'day') {
      if (nextInputvalue.length >= e.target.maxLength && yearRef.current) {
        yearRef.current.focus();
        yearRef.current.setSelectionRange(0, yearRef.current.value.length);
      }
    }
    const nextValue = set(value ? cloneDeep(value) : {}, ['date', name], nextInputvalue);
    const ok = (name, length) => get(nextValue, ['date', name], '').length >= length;
    const done = ok('month', 1) && ok('day', 1) && ok('year', 4);
    onChange(nextValue, done);
  };

  const padDigits = field => e => {
    try {
      const val = e.target.value;

      if (val && val.length === 1) {
        e.target.value = `0${val}`;
      }

      handleChange(field)(e);
    } catch (e) {}
  };

  const handleYearBlur = e => {
    try {
      const val = e.target.value;
      if (val && val.length === 2) {
        const end00Year = new Date().getFullYear() % 1000;
        if (parseInt(val, 10) > end00Year) {
          e.target.value = `19${val}`;
        } else {
          e.target.value = `20${val}`;
        }
      }

      handleChange('year')(e);
    } catch (e) {}
  };

  return (
    <InputWrapper>
      <FormControl variant="filled">
        <InputLabel htmlFor={!month ? monthName : !day ? dayName : !year ? yearName : monthName}>
          <FormattedMessage id={label} defaultMessage={label} />
        </InputLabel>

        <div className={classes.root}>
          <TextField
            placeholder={'MM'}
            variant="filled"
            label={<FormattedMessage id="questionnaire.date.month" defaultMessage="Month" />}
            type="text"
            id={monthName}
            name={monthName}
            value={month}
            onChange={handleChange('month', 0, 12)}
            onBlur={padDigits('month')}
            inputProps={{
              length: 2,
              maxLength: 2,
            }}
          />
          <TextField
            type="text"
            placeholder={'DD'}
            variant="filled"
            label={<FormattedMessage id="questionnaire.date.day" defaultMessage="Day" />}
            inputRef={dayRef}
            id={dayName}
            name={dayName}
            value={day}
            onChange={handleChange('day', 0, 31)}
            onBlur={padDigits('day')}
            inputProps={{
              length: 2,
              maxLength: 2,
            }}
          />
          <TextField
            type="text"
            variant="filled"
            label={<FormattedMessage id="questionnaire.date.year" defaultMessage="Year" />}
            placeholder={'YYYY'}
            inputRef={yearRef}
            id={yearName}
            name={yearName}
            value={year}
            onChange={handleChange('year', 0, new Date().getFullYear())}
            onBlur={handleYearBlur}
            inputProps={{
              length: 4,
              maxLength: 4,
            }}
          />
        </div>
      </FormControl>
    </InputWrapper>
  );
};

DateField.defaultValue = { date: {}, progress: 0 };

export default DateField;
