import React, { useLayoutEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { verifyPatientDateOfBirth } from '../../graphql/schema/questionnaire';
import LockedAdornment from './LockedAdornment';
import Typography from '@material-ui/core/Typography';
import DateField from '../Questionnaire/inputs/DateField';
import Alert from '@material-ui/lab/Alert';
import { hospital } from '../../graphql/schema/familyMember';
import { formatPhoneNumber, processPhoneNumbersString } from '../Questionnaire/inputs/PhoneInput';
import { useValueFromParent } from '../../utils/useValueFromParent';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: '1em',
    margin: '1em',
  },
});

const withDateOfBirthAuthentication = Component =>
  withRouter(props => {
    const classes = useStyles();

    const { data } = useQuery(hospital);

    const [status, setStatus] = useState(null);
    const [input, setInput] = useState({ date: null, done: false });
    const verify = useMutation(verifyPatientDateOfBirth);

    const dateOfBirth = useDateOfBirth();

    useLayoutEffect(() => {
      if (!dateOfBirth) {
        return;
      }

      localStorage.setItem('patientDateOfBirth', dateOfBirth);
      setStatus('SUCCESS');
    }, [dateOfBirth]);

    const onInputChange = ({ date }, done) => {
      status && setStatus(null);
      setInput({ date, done });
    };
    const submit = async () => {
      if (input.done) {
        try {
          const isoDate = `${input?.date?.year}-${input?.date?.month}-${input?.date?.day}`;
          await verify({ variables: { patientDateOfBirth: isoDate } });
          localStorage.setItem('patientDateOfBirth', isoDate);
          setStatus('SUCCESS');
        } catch (e) {
          if (e?.message?.indexOf('DateOfBirth Not Valid') > 0) {
            setStatus('NOT_VALID');
          } else {
            setStatus('UNEXPECTED');
          }
        }
      } else {
        setStatus('NOT_DONE');
      }
    };

    const isAuthenticated = status === 'SUCCESS';

    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Box maxWidth="xs" className={classes.root}>
        <LockedAdornment />
        <Box textAlign="center">
          <Typography variant="h3" gutterBottom>
            Patient Verification
          </Typography>
          <Typography>In order to keep your data safe we need to verify your identity.</Typography>
        </Box>
        <Box>
          {(status === 'NOT_VALID' || status === 'UNEXPECTED') && (
            <Alert severity="error">
              The date of birth doesn’t match. Please contact the surgery center {data?.patientHospital?.contact}.
            </Alert>
          )}
          {status === 'NOT_DONE' && (
            <Alert severity="warning">
              You must fill in your date of birth! If you have a problem, please contact the surgery center
              {data?.patientHospital?.contact
                ? 'at ' + formatPhoneNumber(processPhoneNumbersString(data?.patientHospital?.contact).nationalNumbers)
                : ''}
              .
            </Alert>
          )}
        </Box>
        <DateField label="Date Of Birth" value={input} onChange={onInputChange} />
        <Button onClick={submit} color="primary">
          Submit
        </Button>
      </Box>
    );
  });

function useDateOfBirth() {
  return useValueFromParent('DateOfBirth');
}

export default withDateOfBirthAuthentication;
